import moment from "moment";
import axios, { formToJSON } from "axios";
import _, { add } from "lodash";


export interface Addon {
  lookupCode: string;
  count: number;
}

export interface Occupancy {
  from: string;
  to: string;
}

export interface Day {
  day: number;
  overnightRoomsEZ: number;
  overnightRoomsDZ: number;
  totalGuests: number;
  occupancy: Occupancy[];
  seating: string[];
  addons: Addon[];
  serviceTypes: ServiceTypeConfiguration[];
}

export interface AddonProduct {
  visible: boolean;
  checked: boolean;
  sku: string;
  name: string;
  header: string;
  details: string;
  isAddon: boolean;
  isDeduction: boolean;
  lookupCode: string;
  recurring: string;
  count: number;
}

export interface ServiceTypeConfiguration {
  type: 'SMALL' | 'MEDIUM' | 'REGULAR';
  guestsCount: number;
}

export interface IFormState {
  start: Date;
  end: Date;
  duration: string;
  meetingRooms: number;
  prevdayRoomsEZ: number;
  prevdayRoomsDZ: number;
  prevdayServiceTypes: ServiceTypeConfiguration[];
  rooms: number;
  price: string;
  days: Day[];
  service: any;
  placeName: string;
  placeLat: number;
  placeLng: number;
  searchedHotelId: number | null;
}

const initialFormState: IFormState = {
  start: moment().add(1, 'days').endOf('day').toDate(),
  end: moment().add(2, 'days').endOf('day').toDate(),
  duration: 'Ganztag',
  meetingRooms: 1,
  days: [
    {
      day: 0,
      overnightRoomsEZ: 0,
      overnightRoomsDZ: 0,
      totalGuests: 10,
      occupancy: [{
        from: '08:00',
        to: '18:00'
      }],
      seating: ['UFORM'],
      addons: [],
      serviceTypes: [
        {
          type: 'REGULAR',
          guestsCount: 10
        }
      ]
    },
    {
      day: 1,
      overnightRoomsEZ: 0,
      overnightRoomsDZ: 0,
      totalGuests:10,
      occupancy: [{
        from: '08:00',
        to: '18:00'
      }],
      seating: ['UFORM'],
      addons: [],
      serviceTypes: [
        {
          type: 'REGULAR',
          guestsCount: 10
        }
      ]
    },
  ],
  rooms: 0,
  prevdayRoomsEZ: 0,
  prevdayRoomsDZ: 0,
  prevdayServiceTypes: [
    {
      type: 'REGULAR',
      guestsCount: 0
    }
  ],
  placeName: typeof window !== 'undefined' ? new URLSearchParams(window?.location.search).get('place') || 'Wien, Österreich' : 'Wien, Österreich',
  placeLat: 48.20849,
  placeLng: 16.37208,
  price: '0',
  service: {
    sku: ''
  },
  searchedHotelId: null
};

export const getUserLocation = async (): Promise<{ latitude: number, longitude: number, placeName: string }> => {
  return new Promise((resolve, reject) => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=AIzaSyAqGm1p5W-J2ghfJ4eSJq4yjpMS3yett7Y`)
          .then((res) => {
            const place = res.data.results[0].formatted_address;
            resolve({ latitude, longitude, placeName: place });
          })
          .catch((e) => {
            reject(e);
          })
      }, e => {
        reject(e);
      })
    } else {
      reject("Geolocation is not supported by this browser.");
    }
  })
}

const getPreviousState = () => {
  try {
    const urlParams = new URLSearchParams(window?.location.search);
    if (urlParams.get('s')) {
      const state = JSON.parse(urlParams.get('s') as any);
      state.start = new Date(state.start);
      state.end = new Date(state.end);
      return state;
    } else {
      console.log('No state in URL');
      throw new Error('No state in URL')
    }
  } catch (e) {
    try {
      if (localStorage.getItem('formState')) {
        const s = JSON.parse(localStorage.getItem('formState') as any);
        if (s !== null && s.start && s.end) {
          console.log('State in local storage');
          s.start = new Date(s.start);
          s.end = new Date(s.end);
          return s
        } else {
          console.log('No state in local storage');
          throw new Error('No state in local storage')
        }
      }
    } catch (e) {
      return null
    }
  }
  return null
}

export const getInitialFormState = async (): Promise<IFormState> => {
  /*try {
    const userLocation = await getUserLocation();
    console.log('Initital - User location: ' + userLocation.placeName);
    return {
      ...initialFormState,
      placeLat: userLocation.latitude,
      placeLng: userLocation.longitude,
      placeName: userLocation.placeName
    }
  } catch (e) {
    return initialFormState;
  }*/
    getUserLocation().then((res) => {
      if (_.isNil(localStorage.getItem('formStateManual'))) {
        const state = {
          ...getPreviousState(),
          placeLat: res.latitude,
          placeLng: res.longitude,
          placeName: res.placeName
        }
        updateFormState(state);
        //const event = new CustomEvent('formStateLocationChange', { detail: state });
        //window.dispatchEvent(event);
      }
    }).catch((e) => {
      console.error(e);
    })
    return initialFormState
}

export const getFormState = async (): Promise<IFormState> => {
  const prevState: IFormState = getPreviousState();
  if (prevState) {
    const mergedState: IFormState = {
      ...initialFormState,
      ..._.omit(prevState, ['prevdayGuestsEZ', 'prevdayGuestsDZ']),
      days: prevState.days.map(day => ({
        ...initialFormState.days[0],
        ..._.omit(day, ['overnightGuestsEZ', 'overnightGuestsDZ'])
      }))
    }
    console.log('Previous state: ' + JSON.stringify(prevState));
    console.log('Merged state: ' + JSON.stringify(mergedState));
    return mergedState
  }
  return await getInitialFormState();
}

export const isInitalFormState = async (state: IFormState) => {
  const initialFormState = await getInitialFormState()
  console.log('Initial form state: ' + JSON.stringify(initialFormState));
  console.log('Current form state: ' + JSON.stringify(state));
  return JSON.stringify(state) === JSON.stringify(initialFormState);
}

export const updateFormState = (state: IFormState) => {
  state.start = moment(state.start).endOf('day').toDate();
  state.end = moment(state.end).endOf('day').toDate();
  localStorage.setItem('formState', JSON.stringify(state));
  const event = new CustomEvent('formStateChange', { detail: state });
  window.dispatchEvent(event);
}
