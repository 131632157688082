import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

// Replace the entire type declaration block with this:
declare global {
  interface Window {
    google: any;
  }
}

// Define a type for the Autocomplete instance
type GoogleAutocomplete = {
  getPlace(): any;
  addListener(event: string, callback: () => void): void;
  getPlacePredictions(
    request: {
      input: string;
      types?: string[];
      componentRestrictions?: { country: string | string[] };
    },
    callback: (predictions: any[], status: string) => void
  ): void;
};

// Define a type for hotel search results
interface Hotel {
  id: number;
  name: string;
  locationLatitude: number;
  locationLongitude: number;
}

interface GooglePlacesAutocompleteProps {
  apiKey: string;
  onPlaceSelected: (place: any) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  options?: {
    types?: string[];
    componentRestrictions?: { country: string | string[] };
    fields?: string[];
    bounds?: any;
    strictBounds?: boolean;
  };
  defaultValue?: string;
  className?: string;
  autoFocus?: boolean;
}

const GooglePlacesAutocomplete: React.FC<GooglePlacesAutocompleteProps> = ({
  apiKey,
  onPlaceSelected,
  placeholder = 'Search for a location',
  style,
  options = {},
  defaultValue = '',
  className,
  autoFocus = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<GoogleAutocomplete | null>(null);
  const [inputValue, setInputValue] = useState(defaultValue);
  const [hotels, setHotels] = useState<Hotel[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const resultsContainerRef = useRef<HTMLDivElement>(null);
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);
  
  // Load Google Maps API script
  useEffect(() => {
    // Check if the script is already loaded
    if (window.google && window.google.maps && window.google.maps.places) {
      initAutocomplete();
      return;
    }
    
    // Load the script if not already loaded
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = initAutocomplete;
    document.head.appendChild(script);
    
    return () => {
      // Clean up if component unmounts before script loads
      script.onload = null;
    };
  }, [apiKey]);

  // Add click outside listener to close results
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (resultsContainerRef.current && !resultsContainerRef.current.contains(event.target as Node) && 
          inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setShowResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const initAutocomplete = () => {
    if (!inputRef.current) return;
    
    // We'll use a custom dropdown instead of Google's default
    autocompleteRef.current = new window.google.maps.places.AutocompleteService();
  };

  // Search for hotels using GraphQL
  const searchHotels = async (searchTerm: string) => {
    if (!searchTerm || searchTerm.length < 2) {
      setHotels([]);
      return;
    }

    setIsLoading(true);
    try {
      const priceListService = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'http://127.0.0.1:4031';
      const response = await fetch(`${priceListService}/pricelist/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
            query SearchHotels($searchTerm: String!) {
              searchHotelsByName(searchTerm: $searchTerm, limit: 5) {
                id
                name
                locationLatitude
                locationLongitude
              }
            }
          `,
          variables: {
            searchTerm
          }
        }),
      });

      const result = await response.json();
      if (result.data && result.data.searchHotelsByName) {
        setHotels(result.data.searchHotelsByName);
        setShowResults(true);
      }
    } catch (error) {
      console.error('Error searching hotels:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  // State for Google Places predictions
  const [predictions, setPredictions] = useState<any[]>([]);

  // Search for places using Google Places API
  const searchPlaces = (searchTerm: string) => {
    if (!searchTerm || searchTerm.length < 2 || !autocompleteRef.current) {
      setPredictions([]);
      return;
    }

    autocompleteRef.current.getPlacePredictions(
      {
        input: searchTerm,
        types: options.types || [],
        componentRestrictions: options.componentRestrictions,
      },
      (predictions: any[], status: string) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          setPredictions(predictions);
          setShowResults(true);
        } else {
          setPredictions([]);
        }
      }
    );
  };
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    
    // Search both hotels and places
    searchHotels(value);
    searchPlaces(value);
  };

  const handleHotelSelect = (hotel: Hotel) => {
    // Create a place object similar to what Google Places API would return
    const place = {
      name: hotel.name,
      formatted_address: hotel.name,
      geometry: {
        location: {
          lat: () => hotel.locationLatitude,
          lng: () => hotel.locationLongitude
        }
      },
      // Add the hotel ID to the place object
      hotelId: hotel.id
    };
    
    onPlaceSelected(place);
    setInputValue(hotel.name);
    setShowResults(false);
  };
  
  const handlePlaceSelect = (placeId: string) => {
    // We need to use the Places service to get details for the selected place
    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
    
    placesService.getDetails(
      {
        placeId: placeId,
        fields: ['name', 'formatted_address', 'geometry']
      },
      (place: any, status: string) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
          onPlaceSelected(place);
          setInputValue(place.formatted_address || place.name);
          setShowResults(false);
        }
      }
    );
  };
  
  // Add a state to track focus
  const [isFocused, setIsFocused] = useState(false);
  
  const defaultStyle: React.CSSProperties = {
    backgroundColor: 'rgb(249, 250, 251)',
    border: '1px solid rgb(209, 213, 219)',
    padding: '0.625rem',
    borderRadius: '0.5rem',
    width: '100%',
    boxSizing: 'border-box',
    outline: 'none',
    transition: 'border-color 0.2s ease',
    borderColor: isFocused ? '#AC2A6E' : style?.borderColor || 'rgb(209, 213, 219)',
    boxShadow: isFocused ? '0 0 0 2px rgba(172, 42, 110, 0.2)' : 'none'
  };
  
  // Create a portal container when component mounts
  useEffect(() => {
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.zIndex = '999999';
    document.body.appendChild(container);
    setPortalContainer(container);
    
    return () => {
      document.body.removeChild(container);
    };
  }, []);
  
  // Calculate position for the dropdown
  const updateDropdownPosition = () => {
    if (inputRef.current && portalContainer) {
      const rect = inputRef.current.getBoundingClientRect();
      portalContainer.style.top = `${rect.bottom + window.scrollY}px`;
      portalContainer.style.left = `${rect.left + window.scrollX}px`;
      portalContainer.style.width = `${rect.width}px`;
    }
  };
  
  // Update position when showing results
  useEffect(() => {
    if (showResults) {
      updateDropdownPosition();
      window.addEventListener('scroll', updateDropdownPosition);
      window.addEventListener('resize', updateDropdownPosition);
    }
    
    return () => {
      window.removeEventListener('scroll', updateDropdownPosition);
      window.removeEventListener('resize', updateDropdownPosition);
    };
  }, [showResults]);
  
  // Add effect to focus input when autoFocus is true
  useEffect(() => {
    if (autoFocus && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
        console.log('Input auto-focused');
      }, 100);
    }
  }, [autoFocus]);
  
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        style={{ ...defaultStyle, ...style }}
        onFocus={() => {
          setIsFocused(true);
          if (hotels.length > 0 || predictions.length > 0) {
            setShowResults(true);
            setTimeout(updateDropdownPosition, 0);
          }
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        className={className}
      />
      
      {showResults && portalContainer && ReactDOM.createPortal(
        <div 
          ref={resultsContainerRef}
          style={{
            backgroundColor: 'white',
            border: '1px solid #e5e7eb',
            borderRadius: '0.5rem',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            maxHeight: '300px',
            overflowY: 'auto',
            width: '100%',
            fontFamily: 'sans-serif'
          }}
        >
          {isLoading ? (
            <div style={{ padding: '12px', textAlign: 'center', color: '#6b7280' }}>Suche...</div>
          ) : (hotels.length === 0 && predictions.length === 0) ? (
            <div style={{ padding: '12px', textAlign: 'center', color: '#9ca3af' }}>Keine Ergebnisse gefunden</div>
          ) : (
            <>
              {/* Hotels section */}
              {hotels.length > 0 && (
                <div style={{ padding: '8px 12px', backgroundColor: '#f3f4f6', borderBottom: '1px solid #e5e7eb' }}>
                  <span style={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#4b5563', textTransform: 'uppercase' }}>Hotels</span>
                </div>
              )}
              {hotels.map(hotel => (
                <div
                  key={`hotel-${hotel.id}`}
                  style={{
                    padding: '10px 12px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #e5e7eb',
                    display: 'flex',
                    alignItems: 'center',
                    transition: 'background-color 0.2s ease'
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f9fafb'}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                  onClick={() => handleHotelSelect(hotel)}
                >
                  <div style={{ marginRight: '10px', color: '#4f46e5' }}>🏨</div>
                  <div>
                    <div style={{ fontWeight: '500', color: '#111827' }}>{hotel.name}</div>
                    <div style={{ fontSize: '0.85em', color: '#6b7280' }}>
                      Hotel
                    </div>
                  </div>
                </div>
              ))}
              
              {/* Places section */}
              {predictions.length > 0 && (
                <div style={{ padding: '8px 12px', backgroundColor: '#f3f4f6', borderBottom: '1px solid #e5e7eb' }}>
                  <span style={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#4b5563', textTransform: 'uppercase' }}>Orte</span>
                </div>
              )}
              {predictions.map(prediction => (
                <div
                  key={`place-${prediction.place_id}`}
                  style={{
                    padding: '10px 12px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #e5e7eb',
                    display: 'flex',
                    alignItems: 'center',
                    transition: 'background-color 0.2s ease'
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f9fafb'}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                  onClick={() => handlePlaceSelect(prediction.place_id)}
                >
                  <div style={{ marginRight: '10px', color: '#10b981' }}>📍</div>
                  <div>
                    <div style={{ color: '#111827' }}>{prediction.structured_formatting?.main_text || prediction.description}</div>
                    <div style={{ fontSize: '0.85em', color: '#6b7280' }}>
                      {prediction.structured_formatting?.secondary_text}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>,
        portalContainer
      )}
    </div>
  );
};

export default GooglePlacesAutocomplete; 